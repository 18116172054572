<template>
    <div class="purchaseCatalog-slider">
        <el-tree
            :data="treeData"
            :props="defaultProps"
            :highlight-current="true"
            :expand-on-click-node="false"
            node-key="id"
            :default-expanded-keys="defaultKey"
            :current-node-key="currentNodeKey"
            class="purchaseCatalog-slider-tree"
            ref="tree"
            :indent="0"
        >
            <span class="slot-t-node" slot-scope="{ node, data }">
                <div class="root-node root-node-1" v-if="data.level===1&&data.datatype===1" @click.stop="expandTree(node,data)">
                    <span class="el_left level">{{ data.name }}</span>
                    <span class="el_right">{{ data.remarks }}</span>
                </div>
                <div class="root-node root-node-2" v-else-if="data.level===2&&data.datatype===1" @click.stop="expandTree(node,data)">
                    <span class="el_left level">{{ data.name }}</span>
                    <span class="el_right">{{ data.remarks }}</span>
                </div>
                <div class="root-node root-node-3" v-else-if="data.level===3&&data.datatype===1" @click.stop="expandTree(node,data)">
                    <span class="el_left level">{{ data.name }}</span>
                    <span class="el_right">{{ data.remarks }}</span>
                </div>
                <div class="three-node" v-else-if="data.datatype===2" @click="showMateriel(data, 2)">
                    <div @click.stop="expandTree(node, data)" class="el_left">
                        <!-- <i :class="[!node.expanded?'icon el-icon-caret-right':'icon el-icon-caret-right expanded']"></i> -->
                        <i v-if="node.expanded" class="iconfont iconshouqi"></i>
                        <i v-if="!node.expanded" class="iconfont iconzhankai"></i>
                        <span
                            v-if="!node.expanded"
                            class="wenjianjia iconfont iconwenjianjia-shouqi"
                        ></span>
                        <span
                            v-if="node.expanded"
                            class="wenjianjia iconfont iconwenjianjia-zhankai"
                        ></span>
                    </div>
                    <span class="el_left">{{ data.name }}</span>
                    <span class="iconfont icongengduo el_right" @click.stop="morePop([node], $event)"></span>
                </div>
                <div class="three-node" v-else-if="data.datatype===3" @click="showMateriel(data, 3)">
                    <div @click.stop="expandTree(node,data)" class="el_left">
                        <!-- <i :class="[!node.expanded?'icon el-icon-caret-right':'icon el-icon-caret-right expanded']"></i> -->
                        <i v-if="node.expanded" class="iconfont iconshouqi"></i>
                        <i v-if="!node.expanded" class="iconfont iconzhankai"></i>
                        <span
                            v-if="!node.expanded"
                            class="wenjianjia iconfont iconwenjianjia-shouqi"
                        ></span>
                        <span
                            v-if="node.expanded"
                            class="wenjianjia iconfont iconwenjianjia-zhankai"
                        ></span>
                    </div>
                    <span class="el_left data-name" :title="data.name">{{ data.name }}</span>
                    <span class="el_left create-enter" :title="data.create_org_name" v-if="data.company_id"></span>
                    <span class="iconfont icongengduo el_right" @click.stop="morePop([node], $event)"></span>
                </div>
                <div v-else class="leaf-node" @click="showMateriel(data, 4)">
                    <i class="iconfont iconlingxing el_left"></i>
                    <span class="el_left data-name" :title="data.name">{{ data.name }}</span>
                    <span class="el_left create-enter" :title="data.create_org_name" v-if="data.company_id"></span>
                    <span class="iconfont icongengduo el_right" @click.stop="morePop([node], $event)"></span>
                </div>
            </span>
        </el-tree>
        <div class="more-menu" v-show="menuVisible" id="menu">
            <el-menu>
                <el-menu-item @click="handleAddCatalogs()" v-if="isAdd">
                    <span class="iconfont icontianjia1"></span>
                    <span>选择</span>
                </el-menu-item>
                <!-- <el-menu-item @click="handleAddCatalogs(1)" v-if="isAdd">
                    <span class="iconfont icontianjia1"></span>
                    <span>选择备品备件</span>
                </el-menu-item> -->
                <el-menu-item v-if="isAddCatalog" @click="showAddCatalogDialog=true">
                    <span class="iconfont icontianjia1"></span>
                    <span>添加</span>
                </el-menu-item>
                <el-menu-item v-if="isDelete" @click="delNode">
                    <span class="iconfont iconshanchu"></span>
                    <span>删除</span>
                </el-menu-item>
                <!-- <el-menu-item v-if="isExamine" @click="examineNode">
                    <span class="iconfont iconapproval"></span>
                    <span>审批通过</span>
                </el-menu-item> -->
            </el-menu>
        </div>
        <el-dialog
            title="请输入意见"
            :visible.sync="optionDialog"
            :close-on-click-modal="false"
            width="30%"
            height="30%"
            center
            class="option-dialog"
        >
            <div>
                <el-input type="textarea" v-model="opinionText"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleExamine">审批通过</el-button>
                <el-button @click="optionDialog = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="添加物资目录"
            :visible.sync="showAddCatalogDialog"
            :close-on-click-modal="false"
            width="20%"
            center
            class="addCatalog-dialog"
        >
            <div>
                <el-form ref="form" label-width="80px">
                    <el-form-item label="目录名称">
                        <el-input v-model="customCatalogName"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showAddCatalogDialog = false">取 消</el-button>
                <el-button type="primary" @click="handleAddCatalog">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import jwtDecode from 'jwt-decode';
export default {
    components: {},
    props: {},
    data() {
        return {
            treeData: [{
                id: '1',
                level: 1,
                name: 'Ⅰ级',
                remarks: '总部统一招标采购',
                datatype: 1,
                code: '1',
                types: [{
                    id: '11',
                    level: 1,
                    type: 1,
                    name: '统一招标采购',
                    datatype: 2,
                    code: 'F2-01',
                }],
            }, {
                id: '2',
                level: 2,
                name: 'Ⅱ级',
                remarks: '区域统一招标定点定牌采购',
                datatype: 1,
                code: '2',
                types: [{
                    id: '21',
                    type: 1,
                    name: '统一招标采购',
                    datatype: 2,
                    code: 'F2-02',
                    level: 2,
                },
                {
                    id: '22',
                    type: 2,
                    name: '定点定牌采购',
                    datatype: 2,
                    code: 'F2-02',
                    level: 2,
                },
                {
                    id: '23',
                    type: 3,
                    name: '招标采购',
                    datatype: 2,
                    code: 'F2-02',
                    level: 2,
                },
                ],
            }, {
                id: '3',
                level: 3,
                name: 'Ⅲ级',
                remarks: '企业招标比较互联网采购',
                datatype: 1,
                code: 'F2-03',
                types: [
                    {
                        id: '31',
                        type: 7,
                        name: '互联网采购',
                        datatype: 2,
                        code: 'F2-03-WL',
                        level: 3,
                        addType: 7,
                    },
                    {
                        id: '32',
                        type: 3,
                        name: '招标采购',
                        datatype: 2,
                        code: 'F2-03-ZB',
                        allparent_code: '0,F2,F2-03',
                        level: 3,
                        addType: 3,
                        addCategory: 1,
                    },
                    {
                        id: '33',
                        type: 100,
                        name: '其他',
                        datatype: 2,
                        code: 'F2-03-QT',
                        allparent_code: '0,F2,F2-03',
                        level: 3,
                        addType: 100,
                        addCategory: 1,
                    },
                ],
            }],
            defaultProps: {
                children: 'types',
                label: 'name',
            },
            defaultKey: ['1', '2', '3'], // 默认展开节点id集合
            currentNodeKey: '', // 当前节点key
            currentNode: {}, // 当前节点
            menuVisible: false, // 左侧树弹出菜单
            optionDialog: false, // 审核 
            opinionText: '', // 审核意见
            decodeToken: {}, // 用户token信息
            enterType: 0, // 登录企业类型
            isDelete: false, // 是否有权限删除
            isExamine: false, // 是否有审核权限
            isAdd: false, // 选择
            isShowMore: false,
            moreData: {}, // 操作的data节点数据
            create_org_name: '', // 创建企业名称
            isAddCatalog: false, // 添加物资目录
            showAddCatalogDialog: false, //  添加物资目录弹出框
            customCatalogName: '', // 自定义目录名称
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 展开节点
        async expandTree(node, data) {
            if (node) {
                node.expanded = !node.expanded;
            }
            if (data.datatype === 1) {
                this.showMateriel(data, 1);
            }
            if (data.datatype === 2) {
                if (!data.types) {
                    const res = await this.$axios.get('/interfaceApi/Procurement/catalogs/child/code/' + data.code
                    + '?level=' + data.level + '&type=' + data.type);
                    if (res.length > 0 ) {
                        if (data.code === 'F2-03-WL' || data.code === 'F2-03-QT' || data.code === 'F2-03-ZB') {
                            res.map(item => {
                                item.datatype = 4;
                                return item;
                            });
                            this.$nextTick(() => {
                                this.$refs.tree.updateKeyChildren(data.id, res);
                                this.defaultKey = ['1', '2', '3'];
                                this.defaultKey.push(data.id);
                            });

                        } else {
                            res.map(item => {
                                item.datatype = 3;
                                return item;
                            });
                            this.$nextTick(() => {
                                this.$refs.tree.updateKeyChildren(data.id, res);
                                this.defaultKey = ['1', '2', '3'];
                                this.defaultKey.push(data.id);
                            });
                        }

                    }
                }
            }
            if (data.datatype === 3) {
                if (!data.types) {
                    const res = await this.$axios.get('/interfaceApi/Procurement/catalogs/child/code/' + data.code);
                    if (res.length > 0) {
                        res.map(item => {
                            item.datatype = 4;
                            return item;
                        });
                        this.$nextTick(() => {
                            this.$refs.tree.updateKeyChildren(data.id, res);
                            this.defaultKey = ['1', '2', '3'];
                            this.defaultKey.push(data.id);
                        });
                    }
                }
            }
        },
        // 更新树节点
        async updateTreeNode(data) {
            if (data.datatype === 2) {
                const res = await this.$axios.get('/interfaceApi/Procurement/catalogs/child/code/' + data.code
                    + '?level=' + data.level + '&type=' + data.type);
                if (res.length > 0) {
                    if (data.code === 'F2-03-WL' || data.code === 'F2-03-QT' || data.code === 'F2-03-ZB') {
                        res.map(item => {
                            item.datatype = 4;
                            return item;
                        });
                    } else {
                        res.map(item => {
                            item.datatype = 3;
                            return item;
                        });
                    }
                    this.$nextTick(() => {
                        this.$refs.tree.updateKeyChildren(data.id, res);
                        this.defaultKey = ['1', '2', '3'];
                        this.defaultKey.push(data.id);
                    });
                } else {
                    this.$nextTick(() => {
                        this.$refs.tree.updateKeyChildren(data.id, res);
                        this.defaultKey = ['1', '2', '3'];
                        this.defaultKey.push(data.id);
                    });
                }
            }
            if (data.datatype === 3) {
                const res = await this.$axios.get('/interfaceApi/Procurement/catalogs/child/code/' + data.code);
                if (res.length > 0) {
                    res.map(item => {
                        item.datatype = 4;
                        return item;
                    });
                    this.$nextTick(() => {
                        this.$refs.tree.updateKeyChildren(data.id, res);
                        this.defaultKey = ['1', '2', '3'];
                        this.defaultKey.push(data.id);
                    });
                }
            }
        },
        // 点击更多
        // eslint-disable-next-line complexity
        morePop(nodeData, MouseEvent) {
            if (this.decodeToken.CompanyId) { // 企业
                this.enterType = 5;
            } else if (this.decodeToken.AreaId) { // 片区
                this.enterType = 4;
            } else if (this.decodeToken.EconomyId) { // 经济体
                this.enterType = 3;
            } else if (this.decodeToken.OperatorId) { // 运营区
                this.enterType = 2;
            } else if (this.decodeToken.GroupId) { // 集团
                this.enterType = 1;
            }
            if (this.enterType === 5 || this.enterType === 2 || this.enterType === 1) {
                if (this.enterType === 5) {
                    if (nodeData[0].data.code === 'F2-03-QT' || nodeData[0].data.code === 'F2-03-ZB' || nodeData[0].data.code === 'F2-03-WL') {
                        this.isAddCatalog = true;
                    } else {
                        this.isAddCatalog = false;
                    }
                    if (nodeData[0].data.create_org_id === this.decodeToken.CompanyId) {
                        this.isDelete = true;
                    } else {
                        this.isDelete = false;
                    }
                } else {
                    this.isAddCatalog = false;
                }
                if (this.enterType === 2 || this.enterType === 1) {
                    if (nodeData[0].data.datatype !== 4) {
                        if (nodeData[0].data.level !== 3) {
                            this.isAdd = true;
                        } else {
                            this.isAdd = false;
                        }

                    } else {
                        this.isAdd = false;
                    }
                    if (nodeData[0].data.create_org_id === this.decodeToken.OperatorId
                    || nodeData[0].data.create_org_id === this.decodeToken.GroupId) {
                        this.isDelete = true;
                    } else {
                        this.isDelete = false;
                    }
                } else {
                    this.isAdd = false;
                }
                if (this.isAdd || this.isAddCatalog || this.isDelete) {
                    this.create_org_name = nodeData[0].data.create_org_name;
                    this.moreData = nodeData[0];
                    this.menuVisible = false; // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
                    this.menuVisible = true; // 显示模态窗口，跳出自定义菜单栏
                    this.$forceUpdate();
                    const menu = document.querySelector('#menu');
                    document.addEventListener('click', this.foo); // 给整个document添加监听鼠标事件，点击任何位置执行foo方法
                    menu.style.left = MouseEvent.clientX + 0 + 'px';
                    menu.style.top = MouseEvent.clientY + 10 + 'px';
                } else {
                    this.menuVisible = false;
                }
            }
        },
        foo() { // 取消鼠标监听事件 菜单栏
            this.menuVisible = false;
            document.removeEventListener('click', this.foo); // 要及时关掉监听
        },
        // 删除节点
        delNode() {
            this.$axios.get('/interfaceApi/Procurement/Catalogs/delete/' + this.moreData.data.id)
                .then(() => {
                    this.$message.success('删除成功');
                    this.updateTreeNode(this.moreData.parent.data);
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 审核节点
        examineNode() {
            this.optionDialog = true;
        },
        // 审核
        handleExamine() {
            this.$axios.get('/interfaceApi/Procurement/Catalogs/approval/' + this.moreData.data.id + '/'
            + 1 + '?opinion=' + this.opinionText)
                .then(res => {
                    if (res) {
                        this.$message.success('审核成功');
                        this.optionDialog = false;
                        this.updateTreeNode(this.moreData.parent.data);
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 新增-大类
        addCatalogs(data) {
            this.currentNode = data;
            this.$toast(
                {
                    title: true,
                    type: 'sideslip',
                    width: '15.4rem',
                    t_url: 'tableList/index',
                    extr: {
                        code: { TableCode: 'cgml_xz', QueryString: 'level=' + data.level + '&type='
                        + data.type + '&parent_code=' + data.code + '&parent_id=' + data.id },
                        closeCallback: this.closeCallback,
                    },
                }
            );
        },
        // 新增大类、小类回调
        closeCallback() {
            this.updateTreeNode(this.currentNode);
        },
        // 新增-小类
        // addCatalogsChild(data, category) {
        //     this.currentNode = data;
        //     this.$toast(
        //         {
        //             title: true,
        //             type: 'sideslip',
        //             width: '15.4rem',
        //             t_url: 'tableList/index',
        //             extr: {
        //                 code: { TableCode: 'cgml_xz', QueryString: 'level=' + data.level + '&type=' + data.type + '&category=' + category
        //                 + '&parent_code=' + data.code + '&parent_id=' + data.id },
        //                 closeCallback: this.closeCallback,
        //             },
        //         }
        //     );
        // },
        handleAddCatalogs() {
            this.addCatalogs(this.moreData.data);
            // if (this.moreData.data.datatype === 2) {
            //     this.addCatalogs(this.moreData.data, category);
            // } else {
            //     this.addCatalogsChild(this.moreData.data, category);
            // }
        },
        // 展示右侧物料列表 
        showMateriel(data, type) {
            this.$emit('show-materiel', data, type);
        },
        // 招标采购和其他-添加目录
        handleAddCatalog() {
            let enterType = 0;
            enterType = this.enterType;
            if (this.enterType === 5) {
                enterType = 3;
            }
            const list = [{
                name: this.customCatalogName,
                code: '',
                dic_code: '',
                parent_code: this.moreData.data.code,
                allparent_code: this.moreData.data.allparent_code + ',' + this.moreData.data.code,
                parent_id: '',
                allparent_id: '',
                is_custom: 1, // 固定值
            }];
            this.$axios.post('/interfaceApi/Procurement/catalogs/addroot/'
            + enterType + '/' + this.moreData.data.addType, list)
                .then(res => {
                    if (res) {
                        this.$message.success('添加成功！');
                        this.updateTreeNode(this.moreData.data);
                        this.showAddCatalogDialog = false;
                        this.customCatalogName = '';
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
    created() {
        // 获取缓冲中token
        const token = window.sessionStorage.getItem('token');
        // 解析token，获取token中数据
        const decode = jwtDecode(token);
        this.decodeToken = decode;
    },
    mounted() {},
};
</script>
<style lang="stylus">
.purchaseCatalog-slider
    width 3rem
    height 100%
    background #fff
    overflow-y auto
    overflow-x hidden
    .el-tree-node__content
        height auto
    .el-tree-node__expand-icon
        display none
    .slot-t-node
        width 100%
        font-size: .14rem;
        .icongengduo,.icontianjia1
            width .3rem
            text-align center
            color #5588f1
        .create-enter
            background url(./../../images/enter_icon.png) no-repeat;
            width .16rem
            height .16rem
            background-size 100% 100%
            margin .08rem 0 0 .1rem
        .data-name
            width calc(100% - 1.16rem);
            overflow hidden
            text-overflow ellipsis
            white-space nowrap
    .root-node
        height .4rem
        color #fff
        padding 0 .1rem
        font-size .14rem
        span
            line-height .4rem
        .level
            font-size .18rem
    .three-node
        overflow hidden
        line-height .32rem
        padding-right .1rem
        .icon
            transform rotate(0deg)
            transition transform .3s ease-in-out
            color #999999
        .expanded
            transform: rotate(90deg);
        .wenjianjia
            margin 0 .1rem
            color #999999
        .icontianjia1
            color #5588f1
        .iconzhankai
            color #5588F1
        .iconshouqi
            color #F19F26
        .iconwenjianjia-shouqi
            color #5588F1
        .iconwenjianjia-zhankai
            color #F19F26
    .el-tree-node
        padding-left 0.1rem
    .leaf-node
        line-height .32rem
        overflow hidden
        padding-right .1rem
        .iconlingxing
            font-size .12rem
            color #9099a0
            margin-right .1rem
            margin-left .1rem
        .icongengduo
            color #5588f1
    .gray
        color #c2c7cb
        .iconlingxing
            color #c2c7cb
    .root-node-1
        background-color #5588F1
    .root-node-2
        background-color #EB656F
    .root-node-3
        background-color #21BDCC
    .more-menu
        width 1.7rem
        position absolute
        border 1px solid #d3d5d4
        box-shadow 0 0 .05rem #d3d5d4
        border-bottom none
        z-index 1000
        .iconfont
            margin-right .1rem
        .menu-name
            background #f8f8f8
        .el-menu
            border-right none
            .el-menu-item
                border-bottom 1px solid #f0f0f0
                height .38rem
                line-height .38rem
                &:focus
                    background-color #1577d2
                    color #fff
    .option-dialog
        .el-dialog
            height 350px
            textarea
                height 150px
    .addCatalog-dialog
        .el-dialog
            height 250px
</style>
<style>
.purchaseCatalog-slider .is-current>.el-tree-node__content .leaf-node{
    color:#5588F1;
}
.purchaseCatalog-slider .is-current>.el-tree-node__content .leaf-node .iconlingxing{
    color:#5588F1;
}
.purchaseCatalog-slider .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
    background:#E2EAF0;
}
.purchaseCatalog-slider .el-menu-item.is-active {
    color: #666;
}
.purchaseCatalog-slider .el-tree-node .el-tree-node__content{
    position: relative;
}
/* 树形结构节点添加连线 */
.purchaseCatalog-slider-tree /deep/ .el-tree-node {
  position: relative;
  padding-left: .16rem;
}

.purchaseCatalog-slider-tree /deep/ .el-tree-node__children {
  padding-left: .16rem;
}
.purchaseCatalog-slider-tree>.el-tree-node{
    padding: 0;
}
.purchaseCatalog-slider-tree /deep/ .el-tree-node :last-child:before {
  height: .38rem;
}

.purchaseCatalog-slider-tree /deep/ .el-tree > .el-tree-node:before {
  border-left: none;
}

.purchaseCatalog-slider-tree /deep/ .el-tree > .el-tree-node:after {
  border-top: none;
}

.purchaseCatalog-slider-tree /deep/ .el-tree-node:before {
  content: "";
  left: -.04rem;
  position: absolute;
  right: auto;
  border-width: 1px;
}

.purchaseCatalog-slider-tree /deep/ .el-tree-node:after {
  content: "";
  left: -.04rem;
  position: absolute;
  right: auto;
  border-width: 1px;
}
.purchaseCatalog-slider-tree /deep/ .el-tree-node__expand-icon.is-leaf {
  display: none;
}

.purchaseCatalog-slider-tree /deep/ .el-tree-node:before {
  border-left: 1px dashed #b8b9bb;
  bottom: 0px;
  height: 100%;
  top: -.26rem;
  width: 1px;
}

.purchaseCatalog-slider-tree /deep/ .el-tree-node:after {
  border-top: 1px dashed #b8b9bb;
  height: .20rem;
  top: .15rem;
  width: .18rem;
}
.purchaseCatalog-slider-tree>.el-tree-node:after{
    display: none;
}
</style>